import * as React from "react";
import { graphql } from "gatsby";

import BlogPostLayout from "../components/BlogPostLayout";
import Seo from "../components/Seo";

// markup
const BlogPostTemplate = ({ data }) => {
  const post = data.blogpost.nodes[0];
  return (
    <BlogPostLayout post={post}>
      <Seo title={post.frontmatter.title} description={post.excerpt} />
      <div className="container d-flex justify-content-center">
        <div className="content-wrapper py-5">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <a href="/blog" className="text-button">
            ← Back to all posts
          </a>
        </div>
      </div>
    </BlogPostLayout>
  );
};

export const query = graphql`
  query blogPageQuery($slug: String!) {
    blogpost: allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          datetime(formatString: "dddd, D MMMM YYYY")
          header_image {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
          title
        }
        html
      }
    }
  }
`;

export default BlogPostTemplate;
