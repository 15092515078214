import React from "react";
import BlogPostHeader from "./BlogPostHeader";
import Footer from "./Footer";
import "../styles/style.scss";

const BlogPostLayout = ({ post, children }) => (
  <>
    <div id="wrapper" className="wrapper">
      <BlogPostHeader post={post} />
      {children}
      <Footer />
    </div>
  </>
);

export default BlogPostLayout;
