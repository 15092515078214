import React from "react";
import Navbar from "./Navbar";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogPostHeader = ({ post }) => {
  const image = getImage(post.frontmatter.header_image);

  return (
    <>
      <div className="blog-hero-container">
        <GatsbyImage
          image={image}
          alt=""
          style={{
            gridArea: "1/1",
          }}
          layout="fullWidth"
          aspectRatio={3 / 1}
        />
        <div className="hero-overlay">
          <div className="w-100 align-self-start">
            <Navbar />
          </div>
          <div className="hero-text-new container">
            <h2 className="display-5 text-start">{post.frontmatter.title}</h2>
            <p className="text-start">Published {post.frontmatter.datetime}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPostHeader;
